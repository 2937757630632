import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';

const headerVariants = cva('h-header transition-all duration-200 ease-in-out', {
  variants: {
    variant: {
      dark: 'bg-gray-900',
      light: 'bg-gray-600/50 backdrop-blur-sm',
    },
  },
});

export type HeaderVariantsProps = VariantProps<typeof headerVariants>;

export type HeaderVariants = HeaderContainerProps['variant'];

export type HeaderContainerProps = React.PropsWithChildren<
  HeaderVariantsProps & {
    className?: string;
  }
>;

export function HeaderContainer({ variant, className, children }: HeaderContainerProps) {
  return <header className={clsx(headerVariants({ variant }), className)}>{children}</header>;
}
