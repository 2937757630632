import React, { useCallback } from 'react';
import { Turnstile, type TurnstileInstance } from '@marsidev/react-turnstile';
import { TURNSTILE_SITE_KEY } from 'astro:env/client';
import clsx from 'clsx';

import { useForm } from '@dandapani/shared/forms/use-form';
import { getFieldError } from '@dandapani/shared/forms/utils';
import { request, unwrapResult } from '@dandapani/shared/request';
import { Button } from '@dandapani/web/ui/button';
import { Input } from '@dandapani/web/ui/input';
import { Link } from '@dandapani/web/ui/link';

import { FormError } from './form-error';

export interface SubscribeFormProps {
  className?: string;
  buttonLabel?: React.ReactNode;
  variant?: 'light' | 'dark';
  size?: 'sm' | 'default';
}

interface FormValues {
  email: string;
  token: string;
}

export const SubscribeForm = React.memo(
  ({ className, buttonLabel, variant = 'light', size = 'default' }: SubscribeFormProps) => {
    const turnstileRef = React.useRef<TurnstileInstance | null>(null);

    const {
      register,
      handleSubmit,
      mapFormErrors,
      setValue,
      setError,
      formState: { isSubmitting, isSubmitSuccessful, errors },
    } = useForm<FormValues>();

    const onSubmit = handleSubmit(async ({ email, token }) => {
      if (!token && import.meta.env.PROD) {
        setError('root', { message: 'Please verify the captcha.', type: 'validate' });
        return;
      }

      const response = await request('/v2/activecampaign_subscriptions/subscribe/', {
        method: 'POST',
        body: {
          email,
        },
        headers: {
          'cf-turnstile-response': token,
        },
      });

      if (response.matchError()) {
        mapFormErrors(response);
        unwrapResult(response);
      }
    });

    const onVerify = useCallback(
      (token: string) => {
        setValue('token', token);
      },
      [setValue]
    );

    return (
      <form
        className={clsx('grid transition-all', className, variant === 'dark' && 'text-gray-500')}
        onSubmit={onSubmit}
      >
        {!isSubmitSuccessful ? (
          <>
            <Input
              label="Email address"
              placeholder="Email Address"
              type="email"
              autoComplete="email"
              error={getFieldError(errors.email)}
              minLength={1}
              className="mb-0.5"
              variant={variant === 'dark' ? 'light' : 'dark'}
              size={size === 'sm' ? 'sm' : 'lg'}
              {...register('email', { required: true })}
            />
            {import.meta.env.PROD ? (
              <Turnstile
                siteKey={TURNSTILE_SITE_KEY}
                onSuccess={onVerify}
                ref={turnstileRef}
                options={{ refreshExpired: 'auto', appearance: 'interaction-only', size: 'flexible', theme: variant }}
                className="mb-0.5"
              />
            ) : (
              <div className="h-6" />
            )}
            <FormError error={errors.root} className="mb-4" />
            <Button type="submit" variant="primary" isLoading={isSubmitting} className="mb-4" size={size}>
              {buttonLabel || 'Subscribe'}
            </Button>
            <p className="text-14 italic">
              * By subscribing you agree with our{' '}
              <Link className="underline" href="/terms-and-conditions">
                Terms and Conditions
              </Link>{' '}
              and{' '}
              <Link className="underline" href="/privacy-policy">
                Privacy Policy
              </Link>
              .
            </p>
          </>
        ) : (
          <>
            <p aria-live="polite" className="mb-4 mt-2 text-18 text-red-500">
              Thank you!
            </p>
            <p aria-live="polite" className="text-12 italic">
              Your email has been added to our mailing list. We send only valuable emails to help you live a happier
              life. No spam — useful content only.
            </p>
          </>
        )}
      </form>
    );
  }
);
