import { useCallback, useState } from 'react';
import { Turnstile } from '@marsidev/react-turnstile';
import * as Dialog from '@radix-ui/react-dialog';
import { TURNSTILE_SITE_KEY } from 'astro:env/client';
import clsx from 'clsx';
import { Controller } from 'react-hook-form';

import { useForm } from '@dandapani/shared/forms/use-form';
import { getFieldError } from '@dandapani/shared/forms/utils';
import { useToast } from '@dandapani/shared/hooks/use-toast';
import { request } from '@dandapani/shared/request';
import { Button, type ButtonProps } from '@dandapani/web/ui/button';
import { Icon } from '@dandapani/web/ui/icons';
import { Input, TextArea } from '@dandapani/web/ui/input';
import { Select } from '@dandapani/web/ui/select';

export interface HireDandapaniModalProps {
  className?: string;
  buttonVariant?: ButtonProps['variant'];
  buttonText: string;
  backgroundImage?: React.ReactNode;
  successBackgroundImage?: React.ReactNode;
  mobileSuccessBackgroundImage?: React.ReactNode;
}

export interface FormValues {
  name: string;
  email: string;
  company: string;
  eventType: string;
  budget: string;
  audienceSize: string;
  message: string;
  token: string;
}

const EVENT_TYPES = [
  { label: 'Workshop', value: 'workshop' },
  { label: 'Talk', value: 'talk' },
];

export function HireDandapaniModal({
  className,
  backgroundImage,
  mobileSuccessBackgroundImage,
  successBackgroundImage,
  buttonVariant,
  buttonText,
}: HireDandapaniModalProps) {
  const { toast } = useToast();
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    setValue,
    mapFormErrors,
    formState: { errors },
  } = useForm<FormValues>();

  const onVerify = useCallback(
    (token: string) => {
      setValue('token', token);
    },
    [setValue]
  );

  const onSubmit = handleSubmit(async ({ token, ...body }) => {
    if (!token && import.meta.env.PROD) {
      toast({
        title: 'Please verify the captcha.',
      });
      return;
    }

    const response = await request('/v2/hire_forms/', {
      method: 'POST',
      body,
      headers: {
        'cf-turnstile-response': token,
      },
    });

    if (response.matchError()) {
      mapFormErrors(response);
    } else {
      setSubmitted(true);
    }
  });

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <Button className={clsx(className, 'w-full')} variant={buttonVariant}>
          {buttonText}
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-50 bg-[#2E2E2E] opacity-70 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
        <Dialog.Content className="fixed inset-x-0 top-0 z-50 flex h-screen h-svh justify-center overflow-auto px-4 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95">
          <div
            className={clsx(
              'layout relative my-auto flex max-w-md flex-col bg-gray-900 py-16 text-gray-100 lg:max-w-screen-xl lg:items-end',
              submitted && 'min-h-[70vh]'
            )}
          >
            {!submitted ? (
              <>
                <div className="pointer-events-none absolute left-0 top-0 hidden h-full w-full overflow-hidden lg:block [&_img]:!h-full [&_img]:!w-full">
                  {backgroundImage}
                </div>
                <div className="relative lg:w-2/3">
                  <Dialog.Title className="mb-4 font-serif text-48 lg:mb-16 lg:text-64">Hire Dandapani</Dialog.Title>
                  <form className="grid auto-rows-auto gap-8 lg:grid-cols-2" onSubmit={onSubmit}>
                    <Input
                      variant="light"
                      label="Enter Your Name (required)"
                      placeholder="Your Name *"
                      size="lg"
                      autoComplete="name"
                      error={getFieldError(errors.name)}
                      {...register('name', { required: true })}
                    />
                    <Input
                      variant="light"
                      label="Enter Email Address (required)"
                      placeholder="Email *"
                      size="lg"
                      autoComplete="email"
                      type="email"
                      error={getFieldError(errors.email)}
                      {...register('email', { required: true })}
                    />
                    <Input
                      variant="light"
                      label="Enter Company Name (required)"
                      placeholder="Company Name *"
                      size="lg"
                      autoComplete="organization"
                      error={getFieldError(errors.company)}
                      {...register('company', { required: true })}
                    />
                    <Controller
                      control={control}
                      name="eventType"
                      render={({ field, fieldState: { error } }) => (
                        <Select
                          label="Select Event Type (required)"
                          placeholder="Event Type *"
                          size="lg"
                          variant="light"
                          {...field}
                          options={EVENT_TYPES}
                          error={getFieldError(error)}
                        />
                      )}
                    />
                    <Input
                      variant="light"
                      label="Enter Your Budget (required)"
                      placeholder="Budget *"
                      size="lg"
                      error={getFieldError(errors.budget)}
                      {...register('budget', { required: true })}
                    />
                    <Input
                      variant="light"
                      label="Enter Audience Size (required)"
                      placeholder="Audience Size *"
                      size="lg"
                      error={getFieldError(errors.audienceSize)}
                      {...register('audienceSize', { required: true })}
                    />
                    <TextArea
                      variant="light"
                      label="Enter Message (optional)"
                      placeholder="Message"
                      size="textarea"
                      error={getFieldError(errors.message)}
                      className="lg:col-span-2"
                      {...register('message')}
                    />
                    {import.meta.env.PROD ? (
                      <Turnstile
                        siteKey={TURNSTILE_SITE_KEY}
                        onSuccess={onVerify}
                        options={{
                          refreshExpired: 'auto',
                          appearance: 'interaction-only',
                          size: 'flexible',
                          theme: 'light',
                        }}
                        className="-my-6"
                      />
                    ) : (
                      <div className="-my-6 h-6" />
                    )}
                    <Button type="submit" variant="primary" className="lg:col-start-2">
                      Submit
                    </Button>
                  </form>
                </div>
              </>
            ) : (
              <>
                <div className="pointer-events-none absolute left-0 top-0 h-full w-full overflow-hidden [&_img]:!h-full [&_img]:!w-full">
                  <div className="hidden h-full lg:block">{successBackgroundImage}</div>
                  <div className="block lg:hidden">{mobileSuccessBackgroundImage}</div>
                </div>
                <div className="relative grid auto-rows-auto gap-5 lg:w-2/3 lg:grid-cols-2 lg:gap-6">
                  <Dialog.Title className="font-serif text-48 lg:text-64">Thank you!</Dialog.Title>
                  <p className="w-2/3 text-balance lg:col-start-2 lg:w-full">
                    Thank you for considering Dandapani as a speaker for your event. We will be in touch shortly.
                  </p>
                  <div className="lg:col-start-2 lg:row-start-2">
                    <Dialog.Close asChild>
                      <Button variant="primary" className="w-full lg:w-56">
                        Close
                      </Button>
                    </Dialog.Close>
                  </div>
                </div>
              </>
            )}
            <Dialog.Close asChild>
              <Button variant="tertiary" size="closeCircle" className="absolute right-1 top-1">
                <Icon icon="cross" className="h-6 w-6 text-gray-100" />
              </Button>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
