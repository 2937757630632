import clsx from 'clsx';
import type { FieldErrors } from 'react-hook-form';

import { getFieldError } from '@dandapani/shared/forms/utils';

export interface FormErrorProps {
  error: FieldErrors['root'];
  className?: string;
}

export function FormError({ error, className }: FormErrorProps) {
  const formattedError = getFieldError(error as any);
  if (!formattedError) {
    return null;
  }

  return (
    <p aria-live="polite" className={clsx('text-12 font-medium text-red-400 animate-in fade-in', className)}>
      {formattedError}
    </p>
  );
}
