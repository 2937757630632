import React from 'react';
import { cva } from 'class-variance-authority';

import { useVariant } from './header-context';

const triggerVariants = cva('text-14 block border px-4 py-2 font-serif', {
  variants: {
    variant: {
      dark: 'border-gray-500 text-gray-500',
      light: 'border-gray-900 text-gray-900',
    },
  },
});

export interface DrawerTriggerProps {
  isOpen?: boolean;
}

export const DrawerTrigger = React.forwardRef<HTMLButtonElement, DrawerTriggerProps>(({ isOpen, ...props }, ref) => {
  const variant = useVariant();

  return (
    <button className="flex h-12 items-center" ref={ref} {...props}>
      <span className={triggerVariants({ variant })}>
        {!isOpen && <span className="sr-only">Open </span>}
        {isOpen ? 'Close' : 'Menu'}
      </span>
    </button>
  );
});
