import React, { useEffect, useState } from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';

import { Icon } from '@dandapani/web/ui/icons';
import { Link } from '@dandapani/web/ui/link';

import { DataProvider, useUser } from '../../data';
import Drawer from './drawer';
import { HeaderVariantContext } from './header-context';
import type { HeaderVariantsProps } from './header-utils';
import { HeaderContainer } from './header-utils';

const menuVariants = cva('flex h-full flex-row space-x-12', {
  variants: {
    variant: {
      dark: 'text-gray-500',
      light: 'text-gray-900',
    },
  },
});

export interface HeaderProps extends HeaderVariantsProps {
  activateBackgroundOnScroll?: boolean;
  /**
   * Default is `fixed`.
   */
  position?: 'fixed' | 'sticky';
  logo?: React.ReactNode;
  logoText?: React.ReactNode;
}

export function Header(props: HeaderProps) {
  return (
    <DataProvider>
      <HeaderContent {...props} />
    </DataProvider>
  );
}

const HeaderContent = React.memo(
  ({ variant = 'dark', activateBackgroundOnScroll, position = 'fixed', logo, logoText }: HeaderProps) => {
    const { data, isLoading } = useUser({
      revalidateIfStale: false,
      revalidateOnReconnect: false,
    });

    const [applyVariant, setApplyVariant] = useState(false);

    useEffect(() => {
      function onScroll() {
        if (window.scrollY >= 8 && !applyVariant) {
          setApplyVariant(true);
        } else if (window.scrollY < 8 && applyVariant) {
          setApplyVariant(false);
        }
      }

      if (activateBackgroundOnScroll) {
        window.addEventListener('scroll', onScroll);

        onScroll();

        return () => {
          window.removeEventListener('scroll', onScroll);
        };
      }
    }, [activateBackgroundOnScroll, applyVariant]);

    return (
      <HeaderVariantContext.Provider value={variant}>
        <HeaderContainer
          className={clsx('inset-x-0 top-0 z-50', position === 'sticky' ? 'sticky' : 'fixed')}
          variant={activateBackgroundOnScroll ? (applyVariant ? variant : undefined) : variant}
        >
          <NavigationMenu.Root className="layout grid h-full grid-cols-6">
            <div className="col-span-4 col-start-1 flex items-center gap-4 sm:col-span-2 sm:col-start-3 sm:justify-center md:col-span-4 md:col-start-2 md:gap-8 lg:col-span-2 lg:col-start-1 lg:justify-start">
              {logo}
              {logoText}
            </div>
            <div className="col-span-2 flex items-center justify-end md:col-span-1 md:col-start-6 lg:hidden">
              <Drawer>
                {logo}
                {logoText}
              </Drawer>
            </div>
            <div className="relative z-[1] col-span-6 col-start-2 hidden justify-end lg:col-start-3 lg:grid">
              <NavigationMenu.List className={menuVariants({ variant })}>
                <NavigationMenu.Item>
                  <MenuTrigger>About</MenuTrigger>
                  <Menu>
                    <li>
                      <MenuLink href="/about/">About Dandapani</MenuLink>
                    </li>
                    <li>
                      <MenuLink href="/faq/">FAQ</MenuLink>
                    </li>
                  </Menu>
                </NavigationMenu.Item>

                <NavigationMenu.Item>
                  <NavLink href="/unwavering-focus-book/">Book</NavLink>
                </NavigationMenu.Item>

                <NavigationMenu.Item>
                  <MenuTrigger>Events</MenuTrigger>
                  <Menu>
                    <li>
                      <MenuLink href="/events/events-2024/">Events 2024</MenuLink>
                    </li>
                    <NavigationMenu.Sub>
                      <NavigationMenu.List>
                        <NavigationMenu.Item>
                          <NavigationMenu.Trigger className="flex flex-row items-center gap-2 whitespace-nowrap text-18">
                            <Icon icon="ornament" className="h-4 w-2.5" />
                            Past Events
                            <Icon icon="chevron" dir="right" className="ml-auto h-5 w-5 text-red-500" />
                          </NavigationMenu.Trigger>
                          <NavigationMenu.Content className="absolute left-full top-0 ml-2 origin-[top_left] overflow-hidden rounded-sm bg-gray-100 transition-[width,_height] duration-300 data-[state=closed]:animate-scale-out data-[state=open]:animate-scale-in">
                            <ul className="grid grid-flow-row gap-4 p-4 text-gray-900">
                              <li>
                                <MenuLink href="/events/events-2024/">Events 2024</MenuLink>
                              </li>
                              <li>
                                <MenuLink href="/events/events-2023/">Events 2023</MenuLink>
                              </li>
                              <li>
                                <MenuLink href="/events/events-2022/">Events 2022</MenuLink>
                              </li>
                              <li>
                                <MenuLink href="/events/events-2021/">Events 2021</MenuLink>
                              </li>
                              <li>
                                <MenuLink href="/events/events-2020/">Events 2020</MenuLink>
                              </li>
                              <li>
                                <MenuLink href="/events/events-2019/">Events 2019</MenuLink>
                              </li>
                              <li>
                                <MenuLink href="/events/events-2018/">Events 2018</MenuLink>
                              </li>
                              <li>
                                <MenuLink href="/events/events-2017/">Events 2017</MenuLink>
                              </li>
                              <li>
                                <MenuLink href="/events/events-2016/">Events 2016</MenuLink>
                              </li>
                              <li>
                                <MenuLink href="/events/events-2015/">Events 2015</MenuLink>
                              </li>
                              <li>
                                <MenuLink href="/events/events-2014/">Events 2014</MenuLink>
                              </li>
                              <li>
                                <MenuLink href="/events/events-2013/">Events 2013</MenuLink>
                              </li>
                              <li>
                                <MenuLink href="/events/events-2012/">Events 2012</MenuLink>
                              </li>
                              <li>
                                <MenuLink href="/events/events-2011/">Events 2011</MenuLink>
                              </li>
                              <li>
                                <MenuLink href="/events/events-2010/">Events 2010</MenuLink>
                              </li>
                            </ul>
                          </NavigationMenu.Content>
                        </NavigationMenu.Item>
                      </NavigationMenu.List>
                    </NavigationMenu.Sub>
                  </Menu>
                </NavigationMenu.Item>

                <NavigationMenu.Item>
                  <MenuTrigger>Learn</MenuTrigger>
                  <Menu>
                    <li>
                      <MenuLink href="/happier-life-program/">Happier Life Program</MenuLink>
                    </li>
                    <li>
                      <MenuLink href="/dandapani-app-rituals/">Create Rituals</MenuLink>
                    </li>
                    <li>
                      <MenuLink href="/introduction-to-meditation/">Learn To meditate</MenuLink>
                    </li>
                    <li>
                      <MenuLink href="/free-videos-by-dandapani/">Free Videos</MenuLink>
                    </li>
                    <li>
                      <MenuLink href="/free-audio-talks-by-dandapani/">Free Audios</MenuLink>
                    </li>
                    <li>
                      <MenuLink href="/blog/">Blog</MenuLink>
                    </li>
                  </Menu>
                </NavigationMenu.Item>

                <NavigationMenu.Item>
                  <NavLink href="/free-dandapani-app-courses/">App</NavLink>
                </NavigationMenu.Item>

                <NavigationMenu.Item>
                  {isLoading ? (
                    <div className="flex h-full w-20 items-center gap-2" aria-busy aria-label="Loading profile...">
                      <div className="h-6 w-6 animate-pulse rounded-full bg-gray-800" />
                      <div className="h-4 flex-1 animate-pulse rounded-sm bg-gray-800" />
                    </div>
                  ) : data ? (
                    <NavLink href="/profile/" className="group min-w-20 gap-2">
                      <span className="sr-only">Navigate to profile page</span>
                      <span
                        className="flex h-6 w-6 items-center justify-center rounded-full border border-gray-800 bg-gray-500 text-gray-800 transition-opacity duration-300 group-hover:text-red-500 group-hover:opacity-80"
                        aria-hidden
                      >
                        <span className="font-deck text-base uppercase">{data.firstName?.charAt(0) || 'P'}</span>
                      </span>
                      <span className="flex-1 rounded-sm" aria-hidden>
                        {data.firstName || 'Profile'}
                      </span>
                    </NavLink>
                  ) : (
                    <NavLink href="/auth/sign-in">Log In</NavLink>
                  )}
                </NavigationMenu.Item>
              </NavigationMenu.List>
            </div>
          </NavigationMenu.Root>
        </HeaderContainer>
        {position === 'sticky' && variant === 'light' && (
          <div className="h-header absolute inset-x-0 top-0 bg-gray-500" />
        )}
      </HeaderVariantContext.Provider>
    );
  }
);

function MenuTrigger({ children }: React.PropsWithChildren) {
  return (
    <NavigationMenu.Trigger className="flex h-full flex-row items-center gap-1.5 font-medium">
      {children}
      <Icon icon="chevron" dir="bottom" className="h-5 w-5 text-red-500" />
    </NavigationMenu.Trigger>
  );
}

type LinkProps = React.PropsWithChildren<{ href: string; className?: string }>;

function NavLink({ href, children, className }: LinkProps) {
  return (
    <NavigationMenu.Link asChild>
      <Link className={clsx('flex h-full items-center font-medium', className)} href={href}>
        {children}
      </Link>
    </NavigationMenu.Link>
  );
}

function Menu({ children }: React.PropsWithChildren) {
  return (
    <NavigationMenu.Content className="absolute top-full origin-[top_center] transition-[width,_height] duration-300 data-[state=closed]:animate-scale-out data-[state=open]:animate-scale-in">
      <ul className="mt-3 grid grid-flow-row gap-4 rounded-sm bg-gray-100 p-4 text-gray-900">{children}</ul>
    </NavigationMenu.Content>
  );
}

function MenuLink({ href, children }: LinkProps) {
  return (
    <NavigationMenu.NavigationMenuLink asChild>
      <Link href={href} className="flex flex-row items-center gap-2 whitespace-nowrap text-18">
        <Icon icon="ornament" className="h-4 w-2.5" />
        {children}
      </Link>
    </NavigationMenu.NavigationMenuLink>
  );
}
