import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import * as Dialog from '@radix-ui/react-dialog';

import { Button } from '@dandapani/web/ui/button';
import { Icon } from '@dandapani/web/ui/icons';
import { Link } from '@dandapani/web/ui/link';

import { useUser } from '../../data';
import { DrawerTrigger } from './drawer-trigger';
import { useVariant } from './header-context';
import { HeaderContainer } from './header-utils';

export default function Drawer({ children }: React.PropsWithChildren) {
  const variant = useVariant();
  const { data, isLoading } = useUser({
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <DrawerTrigger />
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-50" />
        <Dialog.Content className="group fixed inset-x-0 top-0 z-50 h-screen h-svh overflow-auto shadow-lg">
          <HeaderContainer variant={variant} className="sticky top-0 z-50">
            <nav>
              <ul className="layout grid h-16 grid-cols-3">
                <li className="col-span-2 flex items-center gap-4 md:col-span-1 md:col-start-2 md:justify-center md:gap-8">
                  {children}
                </li>
                <li className="flex grid-cols-3 items-center justify-end">
                  <Dialog.Close asChild>
                    <DrawerTrigger isOpen />
                  </Dialog.Close>
                </li>
              </ul>
            </nav>
          </HeaderContainer>
          <div className="min-h-fs flex flex-col bg-gray-900 text-gray-500 transition ease-in-out group-data-[state=closed]:duration-300 group-data-[state=open]:duration-500 group-data-[state=open]:animate-in group-data-[state=closed]:animate-out group-data-[state=closed]:slide-out-to-top group-data-[state=open]:slide-in-from-top">
            <Accordion.Root className="px-4 pb-28 pt-10" type="multiple" asChild>
              <ul className="space-y-6">
                {isLoading ? (
                  <li>
                    <div className="flex h-full w-40 items-center gap-4" aria-busy aria-label="Loading profile...">
                      <div className="h-12 w-12 animate-pulse rounded-full bg-gray-800" />
                      <div className="h-8 flex-1 animate-pulse rounded-sm bg-gray-800" />
                    </div>
                  </li>
                ) : data ? (
                  <li>
                    <Link
                      href="/profile/"
                      className="group flex items-center gap-4 transition-colors duration-300 hover:text-red-500"
                    >
                      <span className="sr-only">Navigate to profile page</span>
                      <span
                        className="flex h-12 w-12 items-center justify-center rounded-full border-2 border-gray-600 bg-gray-500 text-gray-800"
                        aria-hidden
                      >
                        <span className="font-deck text-32 uppercase">{data.firstName?.charAt(0) || 'U'}</span>
                      </span>
                      <span className="flex-1 font-serif text-32" aria-hidden>
                        Hello, {data.firstName || 'User'}
                      </span>
                    </Link>
                  </li>
                ) : null}

                <li>
                  <Link href="/about/">
                    <Title>About</Title>
                  </Link>
                </li>
                <li>
                  <Link href="/unwavering-focus-book/">
                    <Title>Book</Title>
                  </Link>
                </li>
                <Accordion.Item value="events" asChild>
                  <li>
                    <AccordionTrigger>Events</AccordionTrigger>
                    <AccordionContent>
                      <AccordionLink href="/events/events-2024/">Events 2024</AccordionLink>
                      <AccordionLink href="/events/events-2023/">Events 2023</AccordionLink>
                      <AccordionLink href="/events/events-2022/">Events 2022</AccordionLink>
                      <AccordionLink href="/events/events-2021/">Events 2021</AccordionLink>
                      <AccordionLink href="/events/events-2020/">Events 2020</AccordionLink>
                      <AccordionLink href="/events/events-2019/">Events 2019</AccordionLink>
                      <AccordionLink href="/events/events-2018/">Events 2018</AccordionLink>
                      <AccordionLink href="/events/events-2017/">Events 2017</AccordionLink>
                      <AccordionLink href="/events/events-2016/">Events 2016</AccordionLink>
                      <AccordionLink href="/events/events-2015/">Events 2015</AccordionLink>
                      <AccordionLink href="/events/events-2014/">Events 2014</AccordionLink>
                      <AccordionLink href="/events/events-2013/">Events 2013</AccordionLink>
                      <AccordionLink href="/events/events-2012/">Events 2012</AccordionLink>
                      <AccordionLink href="/events/events-2011/">Events 2011</AccordionLink>
                      <AccordionLink href="/events/events-2010/">Events 2010</AccordionLink>
                    </AccordionContent>
                  </li>
                </Accordion.Item>
                <Accordion.Item value="learn" asChild>
                  <li>
                    <AccordionTrigger>Learn</AccordionTrigger>
                    <AccordionContent>
                      <AccordionLink href="/happier-life-program/">Happier Life Program</AccordionLink>
                      <AccordionLink href="/dandapani-app-rituals/">Create Rituals</AccordionLink>
                      <AccordionLink href="/introduction-to-meditation/">Meditation Course</AccordionLink>
                      <AccordionLink href="/free-videos-by-dandapani/">Free Videos</AccordionLink>
                      <AccordionLink href="/free-audio-talks-by-dandapani/">Free Audios</AccordionLink>
                      <AccordionLink href="/blog/">Blog</AccordionLink>
                    </AccordionContent>
                  </li>
                </Accordion.Item>
                <Accordion.Item value="speaker" asChild>
                  <li>
                    <AccordionTrigger>Speaker</AccordionTrigger>
                    <AccordionContent>
                      <AccordionLink href="/dandapani-international-keynote-speaker-on-focus-meditation-purpose/">
                        Overview
                      </AccordionLink>
                      <AccordionLink href="/dandapani-international-keynote-speaker-on-focus-meditation-purpose/">
                        Types of Events
                      </AccordionLink>
                    </AccordionContent>
                  </li>
                </Accordion.Item>
                <li>
                  <Link href="/free-dandapani-app-courses/">
                    <Title>App</Title>
                  </Link>
                </li>
                {!isLoading && !data && (
                  <li>
                    <Link href="/auth/sign-in/">
                      <Title>Sign In</Title>
                    </Link>
                  </li>
                )}
              </ul>
            </Accordion.Root>
            <div className="fixed inset-x-0 bottom-0 px-4 py-5">
              <Button variant="primary" className="w-full" asChild>
                <Link href="/happier-life-program/">Discover Learning Path</Link>
              </Button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

function Title({ children }: React.PropsWithChildren) {
  return <span className="font-serif text-32">{children}</span>;
}

function AccordionTrigger({ children }: React.PropsWithChildren) {
  return (
    <Accordion.Trigger className="flex w-full flex-row items-center justify-between [&[data-state=open]>svg]:rotate-[270deg]">
      <Title>{children}</Title>
      <Icon icon="chevron" className="h-6 w-6 text-red-500 transition-transform duration-200" dir="bottom" />
    </Accordion.Trigger>
  );
}

function AccordionContent({ children }: React.PropsWithChildren) {
  return (
    <Accordion.Content className="overflow-hidden transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
      <div className="grid grid-cols-12">
        <ul className="col-span-12 col-start-5 grid auto-rows-auto gap-4 py-4">{children}</ul>
      </div>
    </Accordion.Content>
  );
}

function AccordionLink({ href, children }: React.PropsWithChildren<{ href: string }>) {
  return (
    <li>
      <Link href={href} className="text-18 text-gray-600">
        {children}
      </Link>
    </li>
  );
}
